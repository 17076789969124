import moment from 'moment';

function format(dateString, formatConfig = 'MM/DD hh:mm YYYY') {
  return moment(dateString).format(formatConfig);
}

function formatDataDate(date) {
  try {
    if (isNaN(date)) {
      throw new Error();
    }
    let value = date;
    // timestamp digit < 13, not millisecond
    if (date.toString().length < 13) {
      value = date * 1000;
    }
    // timezone offset;
    const offset = new Date().getTimezoneOffset() * 60000;
    const str = new Date(value - offset).toISOString();
    // formating
    return str.slice(0, 16).replace('T', ' ');
  } catch (error) {
    return '-';
  }
}

function generateDates(
  dateFrom,
  dateTo,
  isDateToIncluded = true,
  enableDays = [true, true, true, true, true, true, true],
) {
  let diffDayCount =
    moment(dateTo, 'YYYY-MM-DD').diff(moment(dateFrom, 'YYYY-MM-DD'), 'days') +
    (isDateToIncluded ? 1 : 0);

  return [...new Array(diffDayCount)]
    .map((i, idx) =>
      moment(dateFrom, 'YYYY-MM-DD').startOf('day').add(idx, 'days'),
    )
    .filter((md) => enableDays[md.day()])
    .map((md) => md.format('YYYY-MM-DD'));
}

function formatDateStrToRepublicEra(dateStr) {
  if (!dateStr) {
    return '-';
  }
  if (!/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(dateStr)) {
    return '-';
  }
  let [year, month, date] = dateStr.split('-');
  return `民國 ${Number(year) - 1911}年 ${month}月 ${date}日`;
}

export {format, formatDataDate, generateDates, formatDateStrToRepublicEra};
